<template>
  <!--  <base-switch  v-model="Obscuro" on-text="Obscuro" off-text="Claro" @input="toggleMode"></base-switch>-->
  <!--  <div class="custom-control custom-switch custom-switch-md">
      <input type="checkbox" class="custom-control-input" id="customSwitch3">
      <label class="custom-control-label" for="customSwitch3">Default Unchcked Switch</label>
    </div>
    -->
  <MazSwitch v-model="Switch"  class="mt-2" color="dark"/>
</template>

  <script>
    import { BaseSwitch } from 'src/components';
    import BaseService from '@/api-services/base.service'
    import { MazSwitch } from 'maz-ui'
    export default {
      name: 'Main-Tema',
      components: {
        BaseSwitch,
        MazSwitch,
      },
      data() {
        return {
          Obscuro: true,
          
        }
      },
      mounted() {

        // if (notificacionID)
        //
        if (localStorage.Obscuro != null) {
          this.Obscuro = localStorage.Obscuro == "true";
         // console.debug(localStorage.Obscuro);
        }
     //   this.toggleMode(this.Obscuro);
      },
      computed: {
        Switch      : {
          get: function () {

            return !this.Obscuro;
          },
          set: function (valor) {
            this.Obscuro = !valor;
          }
        },
      },
      watch: {

        Obscuro(nuevoValor) {
          if (localStorage.Obscuro != nuevoValor) {
            localStorage.Obscuro = nuevoValor;
            this.$appInsights.trackEvent({ name: this.$options.name }, { Obscuro: nuevoValor });
          }
          BaseService.CabiarTema(nuevoValor);
        },

      },
      methods: {

        toggleMode(type) {
          if (localStorage.Obscuro != type) {
            localStorage.Obscuro = type;
            this.$appInsights.trackEvent({ name: this.$options.name }, { Obscuro: type });
          }
          BaseService.CabiarTema(type);
          /*
          let docClasses = document.body.classList;
          if (type == true) {
            docClasses.remove('white-content');
            docClasses.add('maz-is-dark');

          } else {
            docClasses.add('white-content');
            docClasses.remove('maz-is-dark');
          }*/
        },

      }
    };</script>
  <style lang="scss">
    @mixin switch($res: 'sm') {
      $index: 1rem;
      $mainVal: 1rem;

      @if $res == 'md' {
        $index: 2rem;
        $mainVal: 1.5rem;
      }
      @else if $res == 'lg' {
        $index: 3rem;
        $mainVal: 2rem;
      }
      @else if $res == 'xl' {
        $index: 4rem;
        $mainVal: 2.5rem;
      }

      .custom-control-label {
        padding-left: #{$index};
        padding-bottom: #{$mainVal};
      }

      .custom-control-label::before {
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
      }

      .custom-control-label::after {
        width: calc(#{$mainVal} - 4px);
        height: calc(#{$mainVal} - 4px);
        border-radius: calc(#{$index} - (#{$mainVal} / 2));
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(calc(#{$mainVal} - 0.25rem));
      }
    }

    // YOU CAN PUT ALL RESOLUTION HERE
    // sm - DEFAULT, md, lg, xl
    .custom-switch.custom-switch-sm {
      @include switch();
    }

    .custom-switch.custom-switch-md {
      @include switch('md');
    }

    .custom-switch.custom-switch-lg {
      @include switch('lg');
    }

    .custom-switch.custom-switch-xl {
      @include switch('xl');
    }
  </style>
