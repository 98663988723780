import { render, staticRenderFns } from "./ImageUpload.vue?vue&type=template&id=641c4b84&"
import script from "./ImageUpload.vue?vue&type=script&lang=js&"
export * from "./ImageUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports