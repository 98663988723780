import Base from "@/api-services/base.service";

export default {
  loginRequest: {
    scopes: [
      //   "User.Read"
      "openid",
      "profile",
    ],
  },
  config: {
    auth: {
      clientId: "e824bb05-c61f-43d2-b08e-b7a902b42336",
      /*    tenantId: "953fdba4-4022-47df-b33d-27e8708522e1",*/
      tenantName: "common",
      // tenantName: "eclockad",
      //authority: 'https://login.microsoftonline.com/common/',
      authority: Base.AuthorityURL,

      knownAuthorities: ["https://eclockad.b2clogin.com"],

      autoRefreshToken: true,
      //navigateToLoginRequestUrl: true,
      navigateToLoginRequestUrl: false,
      //redirectUri: "http://localhost:8080/?ad=1",
      redirectUri: Base.AuthURL,
      postLogoutRedirectUri: `${Base.AuthURL}/#/presenta`,
      /*   onToken: (ctx, error, response) => {
            console.debug(response);
          },*/
    },
    mode: "redirect",
    cache: {
      cacheLocation: "localStorage",
    },
  },

  handleRedirectPromise(msalT, next) {
    msalT.handleRedirectPromise().then((response) => {
      var ElNext = next;
      if (response !== null) {
        var accountId = response.account.homeAccountId;
        console.debug(accountId);

        this.ActualizaToken(msalT, ElNext);
        //ElNext();
        // Display signed-in user content, call API, etc.
      } else {
        // In case multiple accounts exist, you can select
        const currentAccounts = msalT.getAllAccounts();

        if (currentAccounts.length === 0) {
          // no accounts signed-in, attempt to sign a user in
          msalT.loginRedirect(this.loginRequest);
        } else {
          this.ActualizaToken(msalT, ElNext);
        }
      }
    });
  },
  isTokenExpired(token) {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    const Fecha = Math.floor(new Date().getTime() / 1000);
    return Fecha >= expiry;
  },

  ActualizaToken(msalT, ElNext) {

    var ElToken = localStorage.sesion;
    if (ElToken) {
      if (!this.isTokenExpired(ElToken)) {
        ElNext();
        return;
      }
    }
    console.debug("ActualizandoToken");
    const currentAccounts = msalT.getAllAccounts();
    var request = {
      scopes: [
        "openid",
        "profile",
        //  "User.Read"
      ],
      account: currentAccounts[0], // will default to CacheLookupPolicy.Default if omitted
    };
    msalT
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        console.debug("ActualizaToken");
        localStorage.sesion = tokenResponse.idToken;
        localStorage.EsSSO = 1;
        ElNext();
      })
      .catch((error) => {
        try {
          console.error(error);
          if (error.errorCode == "InteractionRequired") {
            return msalT.acquireTokenRedirect(request);
          } else if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalT.acquireTokenRedirect(request);
          }
        } catch {
          console.error(error);
          return msalT.acquireTokenRedirect(request);
        }
      });
  },
};
