import Base from "@/api-services/base";
const BaseURL = `PeriodosNominas`;

export default {
  GetsResponse: null,
  Listado: [],
  FotoURL(Registro) {
    return "/img/grupos.svg";
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null) OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      { PaginaLen: 1000 },
      (response) => {
        this.GetsResponse = response;
        this.Listado = response.data.listado;
        if (OnCorrecto != undefined) OnCorrecto(response);
      },
      OnErroneo,
      OnFinalizado
    );
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(
      Vista,
      BaseURL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //quita lo borrado
  patch(Vista, ID, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.patch(Vista, URL, {}, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
};
