<template>
  <div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input :id="cbId"
             class="form-check-input"
             type="checkbox"
             :disabled="disabled"
             v-model="model" />
      <span class="form-check-sign"></span>
      <slot> <span v-if="inline">&nbsp;</span> </slot>
    </label>
  </div>
</template>
<script>
  export default {
    name: 'base-checkbox',
    model: {
      prop: 'checked'
    },
    props: {
      checked: [Array, Boolean],
      disabled: Boolean,
      inline: Boolean,
      hasError: Boolean,
      invertido: { type: Boolean, default: false },
    },
    data() {
      return {
        cbId: '',
        touched: false
      };
    },
    computed: {
      model: {
        get() {
          if (this.invertido)
            return !this.checked;
          return this.checked;
        },
        set(check) {
          if (!this.touched) {
            this.touched = true;
          }
          if (this.invertido) {
            this.$emit('input', !check);
            this.$emit('cambio', !check);
          }
          else {
            this.$emit('input', check);
            this.$emit('cambio', check);
          }
          
        }
      },
      inlineClass() {
        if (this.inline) {
          return `form-check-inline`;
        }
        return "";
      }
    },
    created() {
      this.cbId = Math.random()
        .toString(16)
        .slice(2);
    }
  };
</script>
