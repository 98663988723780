<template>

  <modal v-if="Modal" :show.sync="Modal"
         modalContentClasses="card"
         headerClasses="card-header">
    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h3 class="card-title"><i class="mr-2" :class="Icono"></i>{{Titulo}}</h3>
      <h5 >{{Datos}}</h5>
    </div>
    <template>
      <div v-if="Carga.Origen">
        <img :src="Carga.Origen | FotoUsuario" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
        <h4 class="mb-0 pb-0  text-truncate ">{{Carga.Origen.Nombre}} </h4>

        <h5 class="mb-0 pb-0  ">Dice: {{Carga.ElComentario}} </h5>
      </div>
      <div v-if="Carga.Colaborador">
        <img :src="Carga.Colaborador | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
        <h4 class="mb-0 pb-0  text-truncate ">{{Carga.Colaborador.Etiqueta}} </h4>
        <h4 v-if="Carga.TipoIncidencia" class="mb-0 pb-0 text-truncate ">Solicita: {{Carga.TipoIncidencia.Etiqueta}} </h4>
        <h5 v-if="Carga.Comentarios" class="mb-0 pb-0  ">Diciendo: {{Carga.Comentarios}} </h5>
      </div>
      <div v-if="Carga.Dias">
        <p class="mb-0 text-muted">{{TituloDias}}</p>
        <p class="badge badge-info mx-2" v-for="(Dia, index) in Carga.Dias" v-bind:key="index">{{FDia(Carga.Dias[index])}}</p>
      </div>
      <div v-if="Carga.Solicitud && Carga.Solicitud.Dias">
        <p class="mb-0 text-muted">{{TituloDias}}</p>
        <p class="badge badge-info mx-2" v-for="(Dia, index) in Carga.Solicitud.Dias" v-bind:key="index">{{FDia(Carga.Solicitud.Dias[index])}}</p>
      </div>
      <div v-if="Carga.Respuesta" class="text-center">
        <p class="mb-0 text-muted">{{Carga.Respuesta.Respuesta}}</p>
        <p v-if="Carga.Respuesta.Aceptada" class="badge badge-success mx-2">Se acepto tu solicitud</p>
        <p v-else class="badge badge-danger mx-2">Solicitud Rechazada</p>
      </div>
      <textarea v-if="!cargando && EsperaRespuesta"  class="form-control" id="exampleFormControlTextarea1" placeholder="Escribe tus comentarios" rows="2" v-model="Comentarios"></textarea>

      <hr class="my-md-4" />
      <base-button v-if="!cargando && EsperaRespuesta" type="primary" block @click="OnAutorizar(true)">
        <i class="fas fa-check mr-2"></i> Autorizar
      </base-button>

      <div class="text-muted text-center">
        <Main-Cargando :cargando="cargando" :MsgError="MsgError" />

        <router-link v-if="!cargando && EsperaRespuesta" :to="{}" class="d-block mt-2" @click.native="OnAutorizar(false)">

          <i class="fas fa-ban mr-2"> </i>Denegar
        </router-link>
        <router-link v-if="!cargando && !EsperaRespuesta" :to="{}" class="d-block mt-2" @click.native="OnAutorizar(false)">

          <i class="fa fa-times mr-2"> </i>Borrar
        </router-link>
      </div>
    </template>
  </modal>
</template>

<script>
  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');

  import Notificaciones from '@/api-services/notificaciones.service';
  import Colaboradores from '@/api-services/colaboradores.service';
  import Usuarios from '@/api-services/usuarios.service';
  export default {
    name: 'LayOuts-NotificacionModal',
    props: {
      value: Object,
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      cargando: { type: Boolean, default: false },
      MsgError: { type: String, default: '' },
    },
    data() {
      return {
        LaNotificacion: null,
        Carga: null,
        Modal: false,
        Erroneo: '',
        Comentarios: '',
      }
    },
    watch: {
      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        this.Modal = Mostrar;

      },
      Modal(Modal) {
        // return;
        console.debug('update:mostrar');
        if (!Modal)
          this.$nextTick(() => { this.$nextTick(() => { this.$emit('update:mostrar', Modal); });; });
      }

    },

    computed: {
      Datos() {
        return Notificaciones.Datos(this.Carga);

      },
      EsperaRespuesta() {
        return Notificaciones.EsperaRespuesta(this.LaNotificacion.tipoNotificacion);
      },
      TituloDias() {
        if (this.Carga && this.Carga.Dias) {
          if (this.Carga.Dias.length > 1)
            return "Para los siguientes días:";
          return "Para el siguiente día:";
        }
        if (this.Carga && this.Carga.Solicitud && this.Carga.Solicitud.Dias) {
          if (this.Carga.Solicitud.Dias.length > 1)
            return "Para los siguientes días:";
          return "Para el siguiente día:";
        }
        return "";
      },
      Icono() {
        return Notificaciones.Icono(this.LaNotificacion.tipoNotificacion);

        //fa fa-times
      },
      Etiqueta() {
        return Notificaciones.Etiqueta(this.LaNotificacion.tipoNotificacion);
      },
      Titulo() {
        return Notificaciones.Titulo(this.LaNotificacion.tipoNotificacion);
      }
    },
    mounted() {
      this.LaNotificacion = Object.assign({}, this.value);
      this.Carga = JSON.parse(this.value.carga);
      this.Modal = this.Mostrar;
    },
    filters: {
      Foto(Registro) {
        return Colaboradores.FotoURL(Registro);
      },
      FotoUsuario(Registro) {
        return Usuarios.FotoUsuarioURL(Registro);
      },
    },

    methods: {

      FDia(Dia) {
        return moment(Dia).format("dddd D/MMMM/YY");

      },
      OnAutorizar(Autorizar) {
        this.$emit("autorizar", { Autorizar: Autorizar, Comentarios: this.Comentarios });
        /*
        Notificaciones.put(this, this.LaNotificacion.id, {
          respuesta: this.Comentarios,
          aceptada: Autorizar
        }, (response) => {
            
            if (response.data.borrado) {
              console.debug(Notificaciones.Notificaciones);
            for (var i = 0; i < Notificaciones.Notificaciones.length; i++) {
              console.debug(Notificaciones.Notificaciones[i].id);
              if (Notificaciones.Notificaciones[i].id == response.data.id) {

                Notificaciones.Notificaciones.splice(i, 1);
                i--;
              }
            }
          }

          this.Modal = false;
        });*/
      }
    }
  }
</script>

<style scoped>
</style>
