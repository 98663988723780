var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"url":_vm.url,"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return _vm._l((_vm.Menu),function(menu){return _c('sidebar-item',{key:menu.name,attrs:{"link":{
          name: menu.name,
          icon: menu.icon,
          path: menu.path,
        }}},_vm._l((menu.children),function(subMenu){return _c('sidebar-item',{key:subMenu.name,attrs:{"link":{
            name: subMenu.name,
            path: subMenu.path,
          }}})}),1)})}}])}),_c('div',{staticClass:"main-panel",style:(_vm.Estilo),attrs:{"data":_vm.sidebarBackground}},[(!_vm.$route.meta.hideHomeNavBar)?_c('home-navbar',{attrs:{"FotoURL":_vm.FotoURL,"Nombre":_vm.Nombre,"SuscripcionesUsuario":_vm.SuscripcionesUsuario,"soporteURL":_vm.soporteURL}}):_vm._e(),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[(!_vm.Suspendido)?_c('router-view'):_c('base-alert',{attrs:{"type":"danger"}},[_c('strong',[_vm._v("Alerta")]),_vm._v(" Esta suscripción esta suspendida, conectata a atención a cliente. ")])],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }