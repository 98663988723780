
import Base from '@/api-services/base'
const BaseURL = `Notificaciones`;
import Push from 'push.js'
import moment from 'moment'

export default {
  OnAuthCorrectoRnd: 1,
  SignalRIni: false,
  Notificaciones: null,

  tieneAccesosTarea() {
    var Tarea = getAccesosTarea();
    console.debug(Tarea);
    return (Tarea != null);
  },
  getAccesosTarea() {
    var json = localStorage['accesosTarea'];
    if (json == null || json == "")
      return null;
    return JSON.parse(json);
  },
  setAccesosTarea(Tarea) {
    Push.clear();
    if (Tarea == null || Tarea == undefined) {
      this.delAccesosTarea();
      this.TareaNotificacionNotifica(null);
      return;
    }
    var TurnoPregunta = JSON.parse(Tarea.carga);
    if (TurnoPregunta.PreguntaActivo == undefined || TurnoPregunta.PreguntaActivo == null
      || !TurnoPregunta.PreguntaActivo.Preguntar) {
      this.delAccesosTarea();
      return;
    }
    Tarea.TurnoPregunta = TurnoPregunta;
    if (Tarea.horaActualizada == undefined) {
      //Diferencia entre segundos entre la hora del server y la local
      var Segundos = moment().diff(moment(Tarea.actualizadoBD), 'seconds');
      //var SE = moment(Tarea.siguienteEjecucion).add(Segundos, 'secconds').format();

      //  console.debug(Tarea.siguienteEjecucion);
      //Se utiliza la hora desde
      var SE = moment(Tarea.TurnoPregunta.EsperarDesde);
      var SF = SE.add(Segundos, 'seconds');
      

      Tarea.siguienteEjecucion = SF.format();

      var Hasta = moment(moment(Tarea.TurnoPregunta.EsperarHasta).add(Segundos, 'seconds'));
      Tarea.hasta = Hasta.format();
      Tarea.timeOut = Hasta.diff(Tarea.siguienteEjecucion, 'seconds');

      Tarea.horaActualizada = true;
    }


    //  console.debug(Tarea.siguienteEjecucion);
    var json = JSON.stringify(Tarea);

    localStorage['accesosTarea'] = json;
    this.TareaNotificacionNotifica(Tarea);
  },
  delAccesosTarea() {
    localStorage.removeItem('accesosTarea');
  },

  ///Agregar la funcion a notificar, recordar quitarla cuando ya no se use
  TareaNotificaciones: [],
  TareaNotificacionAgrega(Evento) {
    this.TareaNotificaciones.push(Evento);
    var Tarea = this.getAccesosTarea();
    Evento(Tarea);
  },
  TareaNotificacionQuita(Evento) {
    var Pos = this.TareaNotificaciones.indexOf(Evento);
    if (Pos >= 0)
      this.TareaNotificaciones.splice(Pos, 1);
  },
  TareaNotificacionNotifica(Tarea) {
    this.TareaNotificaciones.forEach(Notificacion => Notificacion(Tarea));
  },
  IniciaTareasIniciado: false,
  IniciaTareas() {
    if (this.IniciaTareasIniciado)
      return;
    this.IniciaTareasIniciado = true;
    var Yo = this;
    setInterval(
      function () {
        var Tarea = Yo.getAccesosTarea();

        if (Tarea && (Tarea.notificado == undefined || !Tarea.notificado) && !Tarea.borrado) {
          Yo.TareaNotificacionNotifica(Tarea);
          var SE = moment(Tarea.siguienteEjecucion);
          var Hasta = moment(Tarea.hasta);
          if (SE <= moment() && Hasta >= moment()) {
            //Debo notificar porque debe preguntar
            console.debug(Tarea);
            //var TurnoPregunta = JSON.parse(Tarea.carga);
            var TurnoPregunta = Tarea.TurnoPregunta;
            //var FechaHasta = moment(moment(SE).add(TurnoPregunta.PreguntaActivo.MinutosLogOut, 'minutes'));
            var Tiempo = Hasta.diff(SE, 'seconds');
            
            Tiempo -= 5;
            console.debug(Tiempo);
            var Titulo = `${Yo.Etiqueta("TurnoPreguntaActivo")}`;
            var Cuerpo = `${Yo.Datos(TurnoPregunta)}\n Responde antes de las ${Hasta.format("h:mm:ss a")}`
            Push.create(Titulo, {
              body: Cuerpo,
              icon: '/img/icons/favicon-32x32.png',
              //Para que se quede visible 
              requireInteraction: true,
              timeout: Tiempo*1000,
              onError: function () {
                console.debug("Notificacion Cerrada");
              },
              onClick: function () {
                var UrlAccesoNuevo = `/empleado/acceso-nuevo`
                window.focus();
                if (window.location.href.indexOf(UrlAccesoNuevo) >= 0) {

                }
                else {
                  window.location.replace(UrlAccesoNuevo);
                }
                this.close();
              }
            });
            console.debug(SE);
            Tarea.notificado = true;
            Yo.setAccesosTarea(Tarea);
          }
          
        }
        //console.debug(`Hello-${Yo.Contador++}`);

      }, 10000);
  },
  UltimoEventoObten: null,
  Obten(Vista, OnCorrecto, OnErroneo) {
    if (this.Notificaciones != null) {
      OnCorrecto(this.NoVencidas());
     
      this.UltimoEventoObten = OnCorrecto;
      return;
    }
    this.gets(Vista, {}, (response) => {
      this.Notificaciones = response.data.listado;
     // console.debug("NoVencidas");
      OnCorrecto(this.NoVencidas());
      this.UltimoEventoObten = OnCorrecto;
    }, OnErroneo);
  },
  /*
   * Obtiene las notificaciones que no se han vencido
   * */
  NoVencidas() {
    let today = new Date();
    console.log(this.Notificaciones);

    console.log(today.toISOString());
    return this.Notificaciones.filter(notificacion => !notificacion.borrado &&
      (Date.parse(notificacion.creado) > Date.parse(notificacion.autoBorrado)
        ||
        Date.parse(notificacion.autoBorrado) > today
      ));
  },
  Redirigir(Pagina, LaNotificacion) {
    switch (LaNotificacion.tipoNotificacion) {
      case "TurnoPreguntaActivo":
        Pagina.$router.push(`/empleado/acceso-nuevo?n=${LaNotificacion.id}`);
        return true;
    }
    return false;
  },
  Etiqueta(tipoNotificacion) {
    switch (tipoNotificacion) {
      case "RespuestaIncidencia":
        return "Respuesta de Permiso o Justificación";
      case "SolicitudIncidencia":
        return "Solicita Permiso o Justificación";
      case "SolicitudVacaciones":
        return "Solicita Vacaciones";
      case "RespuestaVacaciones":
        return "Respuesta de Vacaciones";
      case "Comentarios":
        return "Nuevo Comentario";
      case "DispositivoDesconectado":
        return "Dispositivo Desconectado";
      case "TurnoPreguntaActivo":
        return "Pregunta de actividad";
        case "ChecadaFueraUbicacion":
          return "Checada fuera de lugar";
    }
    return "Nueva notificación";
  },

  Titulo(tipoNotificacion) {
    switch (tipoNotificacion) {
      case "RespuestaIncidencia":
        return "Respuesta de Permiso o Justificación";

      case "SolicitudIncidencia":
        return "Solicitud de Permiso o Justificación";
      case "SolicitudVacaciones":
        return "Solicitud de Vacaciones";
      case "RespuestaVacaciones":
        return "Respuesta de Vacaciones";
      case "Comentarios":
        return "Nuevo Comentario";
      case "DispositivoDesconectado":
        return "Dispositivo Desconectado";
      case "TurnoPreguntaActivo":
        return "Pregunta de actividad";
    }
    return "Nueva notificación";
  },
  Carga(Notificacion) {
    if (Notificacion.Carga != undefined && Notificacion.Carga != null)
      return Notificacion.Carga;
    switch (Notificacion.tipoNotificacion) {
      case "SolicitudIncidencia":
      case "SolicitudVacaciones":
      case "SolicitudRegistro":
      case "TurnoPreguntaActivo":
        Notificacion.Carga = JSON.parse(Notificacion.carga);
        return Notificacion.Carga ;
    }
    return null;
  },
  Datos(Carga) {
    if (!Carga)
      return "";
    if (Carga && Carga.Nombre) {
      return Carga.Nombre;
    }

    if (Carga && Carga.PreguntaActivo) {
      return Carga.PreguntaActivo.Pregunta;
    }
    return '';
  },
  Icono(tipoNotificacion) {
    switch (tipoNotificacion) {
      case "RespuestaIncidencia":
      case "SolicitudIncidencia":
        return "fas fa-car-crash";
      case "RespuestaVacaciones":
      case "SolicitudVacaciones":
        return "fas fa-umbrella-beach";
      case "Comentarios":
        return "far fa-comment";
      case "DispositivoDesconectado":
        return "fas fa-handshake-slash";
      case "Comentarios":
        return "fas fa-question";
    }
    return "";
  },
  EsperaRespuesta(tipoNotificacion) {
    switch (tipoNotificacion) {
      case "SolicitudIncidencia":
      case "SolicitudVacaciones":
      case "SolicitudRegistro":
      case "TurnoPreguntaActivo":
        return true;
    }
    return false;
  },
  Agrega(Vista, Datos) {
    var Etiqueta = this.Titulo(Datos.tipoNotificacion);
    console.debug(Datos);
    var EsNueva = false;
    if (this.Notificaciones == null) {
      this.Notificaciones = [];
      this.Notificaciones.push(Datos);
    }
    else {
      var Pos=  this.Notificaciones.findIndex(notificacion => notificacion.id == Datos.id);
      console.debug(Pos);
      if (Pos >= 0) {
        this.Notificaciones.splice(Pos, 1, Datos);
        /*
        //Error notificación duplicada
        //Pienso que es error en SignalR, hay multiples conexiones
        try {
          console.debug("Recargando");
          location.reload();
          console.debug("Recargado");
        }
        catch {

        }
        return;*/
      }
      else {
        EsNueva = true;
        this.Notificaciones.unshift(Datos);
      }
     // console.debug("NoVencidas");

    }

    if (this.UltimoEventoObten != null)
      this.UltimoEventoObten(this.NoVencidas(), EsNueva);
    if (Datos.borrado == true)
      return;
    Vista.$notify({ verticalAlign: 'top', horizontalAlign: 'right', message: Etiqueta });


    //Push.clear();
    Push.create(Etiqueta, {
      body: this.Datos(this.Carga(Datos)),
      icon: '/img/icons/favicon-32x32.png',
      requireInteraction: this.EsperaRespuesta(Datos.tipoNotificacion),
      onClick: function () {
        window.focus();
        window.location.replace(`/n/${Datos.id}`);
        //window.focus();
        this.close();
      }
    });
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = `${Base.URL}/${BaseURL}`;
    return Base.get(Vista, URL, { params: BaseGetsListado }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  put(Vista, ID, Respuesta, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = `${Base.URL}/${BaseURL}/${ID}`;
    return Base.put(Vista, URL, Respuesta, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
}
