<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <div class="navbar-brand text-truncate">
        {{ routeName }} - {{ $SuscripcionesUsuarios.getEtiqueta() }}
      </div>
      
    </div>

    <audio
      ref="audioNotificacion"
      src="@/assets/audio/notificacion.mp3"
      preload="auto"
      id="audioNotificacion"
      muted
    ></audio>
    <ul class="navbar-nav" :class="isRTL ? 'mr-auto' : 'ml-auto'">
      <Tema />
      <div class="search-bar input-group d-lg-none">
        <input
          type="text"
          v-model="searchQuery"
          class="d-lg-none form-control"
          v-on:keyup.enter="OnBuscar"
          placeholder="Buscar..."
        />
        <div class="d-lg-none input-group-addon" @click="OnBuscar">
          <i class="tim-icons icon-zoom-split"></i>
        </div>

        <!-- You can choose types of search input -->
      </div>

      <base-button icon type="primary" @click="searchModalVisible = true" link>
        <i class="tim-icons icon-zoom-split"></i>
      </base-button>

      <modal
        :show.sync="searchModalVisible"
        class="modal-search"
        id="searchModal"
        :centered="false"
        :show-close="true"
      >
        <input
          slot="header"
          v-model="searchQuery"
          type="text"
          class="form-control"
          id="inlineFormInputGroup"
          v-on:keyup.enter="OnBuscar"
          placeholder="Buscar"
        />
      </modal>

      <base-dropdown
        v-if="LasNotificaciones"
        tag="li"
        :menu-on-right="!isRTL"
        title-tag="a"
        title-classes="nav-link"
        menu-classes="dropdown-black "
        class="nav-item"
      >
        <template slot="title">
          <div :class="HayNotificacionClass"></div>
          <i class="tim-icons icon-sound-wave"></i>
          <p class="d-lg-none">Notificaciones</p>
        </template>
        <li
          class="nav-link"
          v-for="(notificaion, index) in LasNotificaciones"
          v-bind:key="notificaion.id"
        >
          <Notificacion
            v-model="LasNotificaciones[index]"
            actualizar="OnActualizar"
          />
        </li>
      </base-dropdown>

      <base-dropdown
        tag="li"
        :menu-on-right="!isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-black "
      >
        <template slot="title">
          <div class="photo"><img :src="FotoURL" /></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">{{ Nombre }}</p>
        </template>

        <li class="nav-link">
          <!--      <a href="#" class="nav-item dropdown-item">
        <i class="tim-icons icon-single-02"></i>
        Mi perfil

      </a>

          to="/usuarios/perfil"
          -->
          <router-link
            to="/usuarios/perfil"
            class="nav-item dropdown-item text-white"
          >
            <i class="tim-icons icon-single-02"></i>
            Mi perfil
          </router-link>
        </li>
        <li class="nav-link">
          <router-link
            to="/usuarios/comentarios"
            href="#"
            class="nav-item dropdown-item text-white"
          >
            <i class="tim-icons icon-bulb-63"></i>
            Comentarios
          </router-link>
        </li>

        <li class="nav-link">
          <router-link
            to="/auditorias"
            href="#"
            class="nav-item dropdown-item text-white"
          >
            <i class="tim-icons icon-light-3"></i>
            Auditoria
          </router-link>
        </li>
        <li class="nav-link">
          <a
            :href="soporteURL"
            target="_blank"
            class="nav-item dropdown-item text-white"
          >
            <i class="tim-icons icon-support-17"></i>
            Soporte
          </a>
        </li>
        <div class="dropdown-divider"></div>
        <li
          class="nav-link"
          v-for="suscripcionUsuario in SuscripcionesUsuario"
          v-bind:key="suscripcionUsuario.suscripcionID"
        >
          <a
            href="#"
            class="nav-item dropdown-item text-white"
            @click="CambiaSuscripcion(suscripcionUsuario.suscripcionID)"
          >
            {{ suscripcionUsuario.suscripcion.etiqueta }}({{
              ObtenEtiquetaPerfil(suscripcionUsuario.perfilID)
            }})
          </a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <router-link to="/logout" class="nav-item dropdown-item text-white">
            <i class="tim-icons icon-user-run"></i>
            Cerrar sesión
          </router-link>
          <!-- <a href="#" class="nav-item dropdown-item">
        <i class="tim-icons icon-user-run"></i>
        Cerrar sesión
      </a>-->
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav, Modal } from "@/components";
import SidebarToggleButton from "./SidebarToggleButton";
import Notificacion from "./Notificacion";
import swal from "sweetalert2";
import Tema from "@/Vistas/Main/Tema.comp.vue";

import Notificaciones from "@/api-services/notificaciones.service";
import Usuarios from "@/api-services/usuarios.service";
import SuscripcionesUsuarios from "@/api-services/suscripciones.usuarios.service";

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
    Modal,
    Notificacion,
    Tema,
  },
  computed: {
    HayNotificacionClass() {
      if (this.LasNotificaciones && this.LasNotificaciones.length > 0)
        return " notification";
      return "";
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return false;
    },
  },
  props: {
    FotoURL: {
      type: String,
      default: "/img/default-avatar.png",
      description: "Contiene la url de la foto del usuario",
    },
    Nombre: {
      type: String,
      default: "El Usuario",
      description: "Contiene el nombre del usuario",
    },
    soporteURL: {
      type: String,
      default: "https://support.eclock.com.mx/portal/es/kb/eclock",
      description: "URL de Soporte Técnico",
    },
    SuscripcionesUsuario: Array,
  },
  data() {
    return {
      Obscuro: false,
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      LasNotificaciones: null,
    };
  },
  mounted() {
    this.Actualizar();
  },
  methods: {
    ObtenEtiquetaPerfil(PerfilID) {
      return SuscripcionesUsuarios.ObtenEtiquetaPerfil(PerfilID);
    },
    Actualizar() {
      var Yo = this;
      Notificaciones.Obten(this, (LasNotificaciones, Sonido) => {
        console.debug(LasNotificaciones);
        Yo.LasNotificaciones = LasNotificaciones;
        if (Sonido) {
          let audio = Yo.$refs.audioNotificacion;
          audio.play();
        }
      });
    },
    OnActualizar(Dato) {
      console.debug(Dato);
      this.Actualizar();
    },
    OnBuscar() {
      this.searchModalVisible = false;
      this.$router.push({ path: "/personal", query: { b: this.searchQuery } });
      this.searchQuery = "";
      this.showMenu = false;
    },
    CambiaSuscripcion(SuscripcionID) {
      console.debug(SuscripcionID);
      SuscripcionesUsuarios.setSuscripcionID(SuscripcionID);
      Usuarios.getPerfilLocal((UsuarioPerfil) => {}, true);
      swal
        .fire({
          title: "Listo",
          text: "Se ha cambiado la suscripción seleccionada.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-fill",
          },
          buttonsStyling: false,
        })
        .then(() => {
          // this.$router.push({ path: '/' });
          window.location.reload(false);
        });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
