<template>
  <div class="text-center mt-7">
    <base-alert v-if="MsgError" type="danger">
      <strong>Error:</strong> {{MsgError}}
    </base-alert>

    <MazSpinner v-if="cargando" :size="48"></MazSpinner>
    <div v-if="$slots.default && !cargando" class="text-left" :class="bodyClasses">
      <slot></slot>
    </div>
    
  </div>
</template>

<script>
  import { MazSpinner } from 'maz-ui'
  export default {
    name: 'Main-Cargando',
    components: { MazSpinner },
    props: {
      MsgError: {
        type: String, default: '',
        description: "Muestra el mensaje de error"},
      //Muestra que esta cargando
      cargando: { type: Boolean, default: true },
      bodyClasses: {
        type: [Object, String],
        description: 'Modal Body css classes'
      },
    },
  }
</script>



<style scoped >
</style >
