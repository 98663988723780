import BaseInput from 'src/components/Inputs/BaseInput.vue';
import BaseDropdown from 'src/components/BaseDropdown.vue';
import Card from 'src/components/Cards/Card.vue';
import BaseButton from 'src/components/BaseButton.vue';
import BaseCheckbox from 'src/components/Inputs/BaseCheckbox.vue';
import BaseAlert from 'src/components/BaseAlert.vue';
import BasePagination from 'src/components/BasePagination.vue';

//import BaseNlert from 'src/components/Navbar/BaseNav.vue';
import Modal from 'src/components/Modal.vue';
/*
import BaseProgress from 'src/components/BaseProgress.vue';
/*
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
/*
import { Input, InputNumber, Tooltip, Popover } from 'element-ui';*/
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
import VueClipboard from 'vue-clipboard2'

import { MazSwitch } from 'maz-ui'
import { MazInput, MazBtn, MazPicker, } from 'maz-ui'
import { MazTabsBar, MazTabsContent, MazTabsContentItem } from 'maz-ui'
import { MazSelect } from 'maz-ui'
import { MazPagination } from 'maz-ui'

import MainCargando from 'src/Vistas/Main/Cargando.comp.vue';

import { Cascader } from 'element-ui';
import { Collapse, CollapseItem } from 'element-ui';

//import "maz-ui/lib/css/index.css"
/*Vue.use(MazBtn);

Vue.use(MazInput);

import { MazPicker } from 'maz-ui'
Vue.use(MazPicker);
*/


const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(Card.name, Card);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(Modal.name, Modal);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(MazInput.name, MazInput);
    Vue.component(MazBtn.name, MazBtn);
    Vue.component(MazPicker.name, MazPicker);
    Vue.component(MazSwitch.name, MazSwitch);

    Vue.component(MazTabsBar.name, MazTabsBar);
    Vue.component(MazTabsContent.name, MazTabsContent);
    Vue.component(MazTabsContentItem.name, MazTabsContentItem);
    Vue.component(MazSelect.name, MazSelect);
    Vue.component(MazPagination.name, MazPagination);
    Vue.component(MainCargando.name, MainCargando);

    Vue.component(Cascader.name, Cascader);
    Vue.component(Collapse.name, Collapse);
        Vue.component(CollapseItem.name, CollapseItem);
    Vue.component(BasePagination.name, BasePagination);

    Vue.use(VueClipboard);
  //  Vue.component(BaseProgress.name, BaseProgress);

  /*  Vue.component(TabPane.name, TabPane);
    Vue.component(Tabs.name, Tabs);
    Vue.component(Collapse.name, Collapse);
    Vue.component(CollapseItem.name, CollapseItem);*/

   // Vue.component(BaseNlert.name, BaseNlert);

 /*   Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);

    Vue.use(Tooltip);
    Vue.use(Popover);*/
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
  }
};

export default GlobalComponents;
