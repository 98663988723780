const isProd = process.env.NODE_ENV === "production";

export default {
  ObtenMenuAdministrador(EsEmbajador) {
    var R = []
    R.push({
      name: "Home",
      icon: 'tim-icons icon-chart-pie-36',
      //<i class="fas fa-user-clock"></i>
      path: '/main/home'
    });

    R.push({
      name: 'Autoservicio',
      icon: 'fas fa-house-user',
      path: '/autoservicio'
    });
    var Personal = {
      name: 'Personal',
      icon: 'far fa-address-book ',
      path: '/personal',
      children: [
        {
          name: 'Listado',
          path: '/personal'
        },
        {
          name: 'Organigrama',
          path: '/organigrama'
        },
        {
          name: 'Departamentos',
          path: '/departamentos'
        },
        {
          name: 'Puestos',
          path: '/puestos'
        },
        {
          name: 'Empresas',
          path: '/empresas'
        },
        {
          name: 'Centros de Costos',
          path: '/centroscostos'
        },
        {
          name: 'Grupos',
          path: '/grupos'
        },
        {
          name: 'Divisiones',
          path: '/divisiones'
        },
        {
          name: 'Gestiones',
          path: '/gestiones'
        }

      ]
    }
    /*  if (!isProd) {
        Personal.children.push({
          name: 'NOM-035-STPS-2018',
          path: '/nom035stps2018'
        });
      }*/
    R.push(Personal);
    //<i class="fas fa-brain"></i>
    R.push({
      name: 'Solicitudes',
      icon: 'fas fa-user-check',
      path: '/solicitudes',
    });

    R.push({
      name: 'Checadas',
      icon: 'fas fa-door-open',
      path: '/accesos',
      children: [
        {
          name: 'Listado',
          path: '/accesos'
        },
        {
          name: 'Modo Foto',
          path: '/accesos/modo-foto'
        },
        {
          name: 'Comedor',
          path: '/accesos/comedor'
        }
        ]

    });

    R.push({
      name: 'Asistencias',
      icon: 'tim-icons icon-time-alarm',
      path: '/asistencias',
      children: [
        {
          name: 'Listado',
          path: '/asistencias'
        },

        {
          name: 'Turnos',
          path: '/turnos'
        },
        {
          name: 'Dias Festivos',
          path: '/diasfestivos'
        },
        {
          name: 'Tipos de incidencias',
          icon: 'tim-icons icon-time-alarm',
          path: '/tiposincidencias'
        },
        {
          name: 'Reglas de Vacaciones',
          path: '/reglasVacaciones'
        },
        {
          name: 'Prenominas',
          path: '/prenominas'
        },
        {
          name: 'Cargos',
          path: '/cargos'
        }
      ]
    });

    var Evaluaciones = {
      name: 'Evaluaciones',
      icon: 'fas fa-brain',
      path: '/evaluaciones',
      children: [
        {
          name: 'NOM-035-STPS-2018',
          path: '/nom035stps2018'
        },
        {
          name: 'Talento y Mejora',
          path: '/revision-mejora'
        },
        {
          name: 'Asistencia',
          path: '/asistencias/metricas'
        },
        {
          name: 'Crecimiento Salarial',
          path: '/sueldos'
        },
      ]
    };
    R.push(Evaluaciones);

    R.push({
      name: 'Dispositivos',
      icon: 'fas fa-fingerprint',
      path: '/dispositivos'
    });

    R.push({
      name: 'Ubicaciones',
      icon: 'fas fa-map-marked',
      path: '/asistencias',
      children: [
        {
          name: 'Listado',
          path: '/ubicaciones'
        },
        {
          name: 'Regiones',
          path: '/regiones'
        },
        {
          name: 'Personal',
          path: '/accesos/mapa'
        },
        {
          name: 'Rondines',
          path: '/rondines'
        }
      ]
    });

    /*  R.push({
        name: 'Monitor de Actividad',
        icon: 'fas fa-desktop',
        path: '/am'
      });*/
    if (EsEmbajador)
      R.push({
        name: 'Embajadores',
        icon: 'fas fa-bullhorn',
        path: '/embajadores'
      });
    // console.debug(R);
    return R;
  },



  ObtenMenuSupervisor(EsEmbajador) {
    var R = []
    R.push({
      name: "Home",
      icon: 'tim-icons icon-chart-pie-36',
      //<i class="fas fa-user-clock"></i>
      path: '/main/home'
    });

    R.push({
      name: 'Autoservicio',
      icon: 'fas fa-house-user',
      path: '/autoservicio'
    });
    R.push({
      name: 'Personal',
      icon: 'far fa-address-book ',
      path: '/personal',
    });

    R.push({
      name: 'Checadas',
      icon: 'fas fa-door-open',
      path: '/accesos'
    });

    R.push({
      name: 'Asistencias',
      icon: 'tim-icons icon-time-alarm',
      path: '/asistencias',

    });
    R.push({
      name: 'Cargos',
      icon: 'fas fa-hand-holding-usd',
      path: '/cargos',
    });
    R.push({
      name: 'Solicitudes',
      icon: 'fas fa-user-check',
      path: '/solicitudes',
    });
    R.push({
      name: 'Ubicacion de Personal',
      icon: 'fas fa-map-marked',
      path: '/accesos/mapa',
    });

    // console.debug(R);
    return R;
  },
  ObtenMenuColaborador(EsEmbajador) {
    var R = []


    R.push({
      name: 'Autoservicio',
      icon: 'fas fa-house-user',
      path: '/'
    });
    R.push({
      name: 'Checar',
      icon: 'far fa-address-book ',
      path: '/personal',
    });

    R.push({
      name: 'Checadas',
      icon: 'fas fa-fingerprint',
      path: '/empleado/acceso-nuevo'
    });

    R.push({
      name: 'Puntualidad',
      icon: 'tim-icons icon-time-alarm',
      path: '/empleado/asistencias',

    });

    R.push({
      name: 'Incidencias',
      icon: 'fas fa-car-crash',
      path: '/empleado/incidencias',

    });
    R.push({
      name: 'Vacaciones',
      icon: 'fas fa-umbrella-beach',
      path: '/empleado/vacaciones',

    });
    //  console.debug(R);
    return R;
    /*        <sidebar-item :link="{
            name: $t('sidebar.mainHome'),
            icon: 'fas fa-home',
            path: '/'
          }">
        </sidebar-item>

        <sidebar-item :link="{
            name: 'Checar',
            icon: 'fas fa-fingerprint',
            path: '/empleado/acceso-nuevo'
          }">
        </sidebar-item>


        <sidebar-item :link="{
            name: 'Puntualidad',
            icon: 'tim-icons icon-time-alarm',
            path: '/empleado/asistencias'
          }">


        </sidebar-item>
        <sidebar-item :link="{
            name: 'Incidencias',
            icon: 'fas fa-car-crash',
            path: '/empleado/incidencias'
          }">
        </sidebar-item>

        <sidebar-item :link="{
            name: 'Vacaciones',
            icon: 'fas fa-umbrella-beach',
            path: '/empleado/vacaciones'
          }">

        </sidebar-item>
        <sidebar-item v-if="MostrarEmbajador" :link="{
            name: 'Embajadores',
            icon: 'fas fa-bullhorn',
            path: '/embajadores'
          }">
        </sidebar-item>*/
  },
  ObtenMenu(PerfilID, EsEmbajador) {

    switch (PerfilID) {

      case "Colaborador": return this.ObtenMenuColaborador(EsEmbajador);
      case "Visor": return this.ObtenMenuSupervisor(EsEmbajador);
      case "Supervisor": return this.ObtenMenuSupervisor(EsEmbajador);
      case "Editor": return this.ObtenMenuSupervisor(EsEmbajador);
      case "Administrador": return this.ObtenMenuAdministrador(EsEmbajador);
      case "SuperAdministrador": return this.ObtenMenuAdministrador(EsEmbajador);
    }

    return [];
  }
}
