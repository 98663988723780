<template>
  <div class="wrapper wrapper-full-page-eClock ">
    <notifications></notifications>
    <base-nav v-if="tieneMenu" v-model="showMenu"
              type="white"
              :transparent="true"
              menu-classes="justify-content-end"
              class="auth-navbar fixed-top">
      <div slot="brand" class="navbar-wrapper">
        <a class="navbar-brand" href="#" v-if="title">{{ title }}</a>
      </div>

    </base-nav>


    <div class="" :class="pageClass">
      <div class="content">
        <zoom-center-transition :duration="pageTransitionDuration"
                                mode="out-in">
          <router-view></router-view>
        </zoom-center-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>


    </div>
  </div>

</template>
<script>
  import { BaseNav } from 'src/components';
  import { ZoomCenterTransition } from 'vue2-transitions';
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import ContentFooter from './ContentFooter.vue';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      BaseNav,
      ZoomCenterTransition,
      ContentFooter
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'black'
      }
    },
    data() {
      return {
        tieneMenu: true,
        showMenu: false,
        menuTransitionDuration: 250,
        pageTransitionDuration: 200,
        year: new Date().getFullYear(),
        pageClass: 'login-page'
      };
    },
    computed: {
      title() {
        return `${this.$route.name}`;
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open');
        this.showMenu = !this.showMenu;
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        this.showMenu = false;
      },
      setPageClass() {
        console.debug(this.$route);
        this.tieneMenu = true;
        switch (this.$route.path) {

          case "/usuarios/nuevasuscripcion":
            this.pageClass = `full-page login-page`;
            break;
          case "/rondines/empleado":
            this.tieneMenu = false;
            this.pageClass = ` `;
            break;
          case "/dispositivos/home":
            this.tieneMenu = false;
            this.pageClass = ` `;
            break;
          default:
            this.pageClass = `full-page login-page`.toLowerCase();
          // this.pageClass = ``.toLowerCase();
        }
        // this.pageClass = `full-page login-page`.toLowerCase();
      }
    },
    beforeDestroy() {
      this.closeMenu();
    },
    beforeRouteUpdate(to, from, next) {
      // Close the mobile menu first then transition to next page
      if (this.showMenu) {
        this.closeMenu();
        setTimeout(() => {
          next();
        }, this.menuTransitionDuration);
      } else {
        next();
      }
    },
    mounted() {
      this.setPageClass();
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
      //  initScrollbar(`full-page`);
        //initScrollbar(`content`);
        initScrollbar(`wrapper`);
      //  initScrollbar(`wrapper-full-page`);
        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }

    },
    watch: {
      $route() {

        this.setPageClass();
      }
    }
  };

</script>
<style lang="scss">
  .navbar.auth-navbar {
    top: 0;
  }

  $scaleSize: 0.8;
  @keyframes zoomIn8 {
    from

  {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  100% {
    opacity: 1;
  }

  }

  .wrapper-full-page .zoomIn {
    animation-name: zoomIn8;
  }

  @keyframes zoomOut8 {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }

  .wrapper-full-page .zoomOut {
    animation-name: zoomOut8;
  }
</style>
